import { useCallback } from 'react';
import { clientWithoutAuth } from 'apollo/clients';
import colors from 'assets/styles/colors';
import TallGameCard from 'components/Cards/TallGameCard';
import FittedCarousel from 'components/Carousel/FittedCarousel';
import ProviderCarousel from 'components/carousels/ProviderCarousel';
import GameCategoryIcon from 'components/GameCategoryIcon';
import { Game, GameCategoryType, OriginalGame, useGetLobbyGamesQuery } from 'generated/graphql';
import { useCarousel } from 'hooks/useCarousel';
// Image imports statically for build-time optimization
// Ref. https://nextjs.org/docs/basic-features/image-optimization#local-images
import { StaticImageData } from 'next/image';
import { useTranslation } from 'next-i18next';
import { calculateCardCount, CarouselType } from 'utils/carousel';
import { categoryLink, providerLink } from 'utils/linkGenerator';

import ogBlackjackTall from '../../../public/images/banners/originals-blackjack-tall.png';
import ogCrash from '../../../public/images/banners/originals-crash.png';
import ogCrashTall from '../../../public/images/banners/originals-crash-tall.png';
import ogDice from '../../../public/images/banners/originals-dice.png';
import ogDiceTall from '../../../public/images/banners/originals-dice-tall.png';
import ogHilo from '../../../public/images/banners/originals-hilo.png';
import ogHiloTall from '../../../public/images/banners/originals-hilo-tall.png';
import ogKeno from '../../../public/images/banners/originals-keno.png';
import ogKenoTall from '../../../public/images/banners/originals-keno-tall.png';
import ogLimbo from '../../../public/images/banners/originals-limbo.png';
import ogLimboTall from '../../../public/images/banners/originals-limbo-tall.png';
import ogMines from '../../../public/images/banners/originals-mines.png';
import ogMinesTall from '../../../public/images/banners/originals-mines-tall.png';
import ogPlinko from '../../../public/images/banners/originals-plinko.png';
import ogPlinkoTall from '../../../public/images/banners/originals-plinko-tall.png';
import { useFavouriteGameIds } from '../../hooks/games/useFavouriteGameIds';

import { HomeTabLobbyWrapper } from './styles';

type ImageDeviceMap = {
  mobile: StaticImageData;
  desktop?: StaticImageData;
};

/** Grab games from data, else fill with dummy polyfill data for loading-state of cards. */
const polyfillGames = [...Array(12).keys()].map(key => ({ id: key.toString() }));

// NOTE: Images for original games are hardcoded for now
const originalImagePrefix = new Map<string, ImageDeviceMap>();
originalImagePrefix.set('originals/crash', { mobile: ogCrashTall, desktop: ogCrash });
originalImagePrefix.set('originals/blackjack', { mobile: ogBlackjackTall });
originalImagePrefix.set('originals/dice', { mobile: ogDiceTall, desktop: ogDice });
originalImagePrefix.set('originals/plinko', { mobile: ogPlinkoTall, desktop: ogPlinko });
originalImagePrefix.set('originals/mines', { mobile: ogMinesTall, desktop: ogMines });
originalImagePrefix.set('originals/limbo', { mobile: ogLimboTall, desktop: ogLimbo });
originalImagePrefix.set('originals/keno', { mobile: ogKenoTall, desktop: ogKeno });
originalImagePrefix.set('originals/hilo', { mobile: ogHiloTall, desktop: ogHilo });

const firstGame = OriginalGame.BLACKJACK; // The first game shown in the original games carousel

const HomeTabLobby = () => {
  const { t } = useTranslation();
  const { carouselSize } = useCarousel();

  const { data: gamesData, loading: gamesLoading } = useGetLobbyGamesQuery({
    client: clientWithoutAuth,
  });

  const favouriteGameIds = useFavouriteGameIds();
  const originalGames =
    gamesData?.originals?.nodes
      ?.filter(g => !!g.originalGame)
      .sort(a => (a.originalGame === firstGame ? -1 : 0)) ?? [];

  const categoricalFilter = useCallback(
    (nodes?: Game[] | null) =>
      gamesLoading ? polyfillGames : (nodes ?? []).filter(g => !!g.image?.key),
    [gamesLoading],
  );

  return (
    <HomeTabLobbyWrapper>
      <FittedCarousel
        size={carouselSize}
        fitCardsFunc={parentWidth => calculateCardCount(parentWidth, CarouselType.GAME_CARDS)}
        title={
          <>
            <GameCategoryIcon
              category={GameCategoryType.ORIGINALS}
              color={colors.primaryViolet}
              scale={1.3}
            />
            <span>{t('sectionShuffleGames')}</span>
          </>
        }
        href={providerLink('shuffle-games')}
        hideViewAll
      >
        {originalGames.map(game => (
          <TallGameCard
            key={game.id}
            game={game as Game}
            isFavourite={favouriteGameIds.includes(game.id)}
          />
        ))}
      </FittedCarousel>

      <FittedCarousel
        size={carouselSize}
        fitCardsFunc={parentWidth => calculateCardCount(parentWidth, CarouselType.GAME_CARDS)}
        title={
          <>
            <GameCategoryIcon
              category={GameCategoryType.SLOTS}
              color={colors.primaryViolet}
              scale={1.3}
            />
            <span>{t('gameSlots')}</span>
          </>
        }
        href={categoryLink('slots')}
        disabled={gamesLoading}
      >
        {categoricalFilter(gamesData?.slots.nodes as Game[]).map(game => (
          <TallGameCard
            key={game.id}
            game={game as Game}
            isFavourite={favouriteGameIds.includes(game.id)}
          />
        ))}
      </FittedCarousel>

      <FittedCarousel
        size={carouselSize}
        fitCardsFunc={parentWidth => calculateCardCount(parentWidth, CarouselType.GAME_CARDS)}
        title={
          <>
            <GameCategoryIcon
              category={GameCategoryType.LIVE_CASINO}
              color={colors.primaryViolet}
              scale={1.3}
            />
            <span>{t('gameLiveCasino')}</span>
          </>
        }
        href={categoryLink('live-casino')}
        disabled={gamesLoading}
      >
        {categoricalFilter(gamesData?.liveCasino.nodes as Game[]).map(game => (
          <TallGameCard
            key={game.id}
            game={game as Game}
            isFavourite={favouriteGameIds.includes(game.id)}
          />
        ))}
      </FittedCarousel>

      <ProviderCarousel />

      <FittedCarousel
        fitCardsFunc={parentWidth => calculateCardCount(parentWidth, CarouselType.GAME_CARDS)}
        size={carouselSize}
        title={
          <>
            <GameCategoryIcon
              category={GameCategoryType.GAME_SHOWS}
              color={colors.primaryViolet}
              scale={1.3}
            />
            <span>{t('gameGameShows')}</span>
          </>
        }
        href={categoryLink('game-shows')}
        disabled={gamesLoading}
      >
        {categoricalFilter(gamesData?.gameShows.nodes as Game[]).map(game => (
          <TallGameCard
            key={game.id}
            game={game as Game}
            isFavourite={favouriteGameIds.includes(game.id)}
          />
        ))}
      </FittedCarousel>

      <FittedCarousel
        size={carouselSize}
        fitCardsFunc={parentWidth => calculateCardCount(parentWidth, CarouselType.GAME_CARDS)}
        title={
          <>
            <GameCategoryIcon
              category={GameCategoryType.TABLE_GAMES}
              color={colors.primaryViolet}
              scale={1.3}
            />
            <span>{t('gameTableGames')}</span>
          </>
        }
        href={categoryLink('table-games')}
        disabled={gamesLoading}
      >
        {categoricalFilter(gamesData?.tableGames.nodes as Game[]).map(game => (
          <TallGameCard
            key={game.id}
            game={game as Game}
            isFavourite={favouriteGameIds.includes(game.id)}
          />
        ))}
      </FittedCarousel>
    </HomeTabLobbyWrapper>
  );
};

export default HomeTabLobby;
