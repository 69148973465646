import colors from 'assets/styles/colors';
import styled from 'styled-components';

const RotatingAnimationWrapper = styled.div<{ $rotate?: boolean }>`
  height: 17px;
  & > svg {
    animation: ${({ $rotate }) => ($rotate ? 'rotation 2s infinite linear' : 'none')};
    transform: rotate(-90deg);
    transform-origin: 8.5px 8.5px;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }

    & circle {
      stroke-dashoffset: 0;
      stroke: ${colors.gray700};
      stroke-width: 2em;
    }

    & #bar {
      stroke: ${colors.primaryViolet};
    }
  }
`;

interface CircularLoadingIndicatorProps {
  scale?: number;
  color?: string;
  viewBox?: string;
  rotate?: boolean;
  percentage: number;
}

// animates the loading indicator
const CircularLoadingIndicator = ({
  scale = 1,
  viewBox = '0 0 200 200',
  rotate = false,
  percentage,
}: CircularLoadingIndicatorProps) => {
  const width = `${scale * 17}`;
  const height = `${scale * 17}`;
  const strokeDashoffset = (2 * Math.PI * 80 * (100 - percentage)) / 100;

  return (
    <RotatingAnimationWrapper $rotate={rotate}>
      <svg
        id="svg"
        width={width}
        height={height}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
      >
        <circle
          r="80"
          cx="100"
          cy="100"
          fill="transparent"
          strokeDasharray="502.64"
          strokeDashoffset="0"
        ></circle>
        <circle
          id="bar"
          r="80"
          cx="100"
          cy="100"
          fill="transparent"
          strokeDasharray="502.64"
          strokeDashoffset="0"
          style={{ strokeDashoffset }}
        ></circle>
      </svg>
    </RotatingAnimationWrapper>
  );
};

export default CircularLoadingIndicator;
