import { ChevronDownWhite, CircularLoadingIndicator } from 'assets/svgs';
import { useTranslation } from 'next-i18next';

import { LoadingBtn, ShowMoreBackground } from '../styles';

const DEFAULT_CARD_HEIGHT_PX = 150;

interface ShowMoreBGProps {
  onClick: () => void;
  loading: boolean;
  percentage: number;
  margin?: boolean;
  height?: number;
  className?: string;
}

const ShowMoreButtonWithBackground = ({
  onClick,
  percentage,
  loading,
  margin = true,
  height = DEFAULT_CARD_HEIGHT_PX,
  className,
}: ShowMoreBGProps) => {
  const { t } = useTranslation();

  return (
    <ShowMoreBackground $height={height} $margin={margin} onClick={onClick} className={className}>
      <LoadingBtn>
        <CircularLoadingIndicator percentage={percentage} rotate={loading} />
        <p>{loading ? t('btnLoading') : t('btnShowMore')}</p>
        <ChevronDownWhite />
      </LoadingBtn>
    </ShowMoreBackground>
  );
};

export default ShowMoreButtonWithBackground;
