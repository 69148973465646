import React from 'react';

import { SvgProps } from '..';

const Provider = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.93333 23L1.96409 20.6719C1.34001 19.8918 1 18.9225 1 17.9234V12.8673C1 12.4783 1.15452 12.1053 1.42958 11.8302C1.70463 11.5552 2.07768 11.4006 2.46667 11.4006C2.85565 11.4006 3.2287 11.5552 3.50376 11.8302C3.77881 12.1053 3.93333 12.4783 3.93333 12.8673V16.534"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3332 23.0001V20.0668C8.33359 19.1967 8.07601 18.346 7.59302 17.6223L6.4764 15.9503C6.38072 15.7918 6.25389 15.6544 6.10358 15.5464C5.95327 15.4383 5.78261 15.3618 5.60191 15.3216C5.42121 15.2814 5.23423 15.2783 5.05228 15.3124C4.87033 15.3465 4.69719 15.4172 4.54333 15.5201C4.26694 15.7053 4.06745 15.9848 3.98216 16.3064C3.89686 16.6279 3.93159 16.9696 4.07986 17.2674L5.40182 19.4674"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0667 23L22.0359 20.6719C22.66 19.8918 23 18.9225 23 17.9234V12.8673C23 12.4783 22.8455 12.1053 22.5704 11.8302C22.2954 11.5552 21.9223 11.4006 21.5333 11.4006C21.1443 11.4006 20.7713 11.5552 20.4962 11.8302C20.2212 12.1053 20.0667 12.4783 20.0667 12.8673V16.534"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6666 23.0001V20.0667C15.6662 19.1967 15.9238 18.346 16.4068 17.6223L17.5234 15.9483C17.6188 15.7896 17.7454 15.6518 17.8955 15.5433C18.0457 15.4349 18.2163 15.358 18.397 15.3174C18.5777 15.2769 18.7648 15.2734 18.9469 15.3072C19.129 15.341 19.3024 15.4114 19.4565 15.5142C19.7342 15.6992 19.9347 15.9793 20.0204 16.3018C20.1061 16.6243 20.0711 16.9669 19.9219 17.2654L18.6 19.4654"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 14.2C15.6452 14.2 18.6001 11.2451 18.6001 7.6C18.6001 3.95492 15.6452 1 12.0001 1C8.35507 1 5.40015 3.95492 5.40015 7.6C5.40015 11.2451 8.35507 14.2 12.0001 14.2Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10.5333C13.62 10.5333 14.9333 9.21999 14.9333 7.59996C14.9333 5.97992 13.62 4.66663 12 4.66663C10.3799 4.66663 9.06665 5.97992 9.06665 7.59996C9.06665 9.21999 10.3799 10.5333 12 10.5333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 4.66667V1" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.4385 5.97006L17.4872 3.93335"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.56128 5.97006L6.51257 3.93335"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 10.5333V14.2" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.4385 9.22998L17.4872 11.2667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.56128 9.22998L6.51257 11.2667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(Provider);
