import React from 'react';

import { SvgProps } from '..';

const Dice = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_6329_9893)">
        <path
          d="M15.9762 8.36293L14.4083 1.28453C14.3507 1.02573 14.1927 0.800357 13.9691 0.657861C13.7455 0.515365 13.4746 0.46738 13.2156 0.524435L6.13728 2.09237C6.00906 2.12077 5.88769 2.17415 5.78009 2.24946C5.6725 2.32477 5.5808 2.42054 5.51022 2.53129C5.43964 2.64204 5.39157 2.76561 5.36875 2.89495C5.34593 3.02428 5.34881 3.15684 5.37722 3.28506L6.03412 6.25068H2C1.73487 6.25098 1.48069 6.35643 1.29322 6.54391C1.10575 6.73138 1.0003 6.98556 1 7.25068V14.5007C1.0003 14.7658 1.10575 15.02 1.29322 15.2075C1.48069 15.3949 1.73487 15.5004 2 15.5007H9.25C9.51513 15.5004 9.76931 15.3949 9.95678 15.2075C10.1442 15.02 10.2497 14.7658 10.25 14.5007V10.6556L15.2162 9.55559C15.475 9.49795 15.7003 9.34002 15.8428 9.11643C15.9853 8.89284 16.0333 8.62186 15.9762 8.36293ZM9.25 14.5007H2V7.25068H6.25566L6.94513 10.3634C6.99437 10.5853 7.11768 10.7838 7.29477 10.9263C7.47186 11.0689 7.69219 11.1469 7.9195 11.1475C7.99291 11.1474 8.06609 11.1394 8.13775 11.1235L9.25025 10.877L9.25062 14.5007H9.25ZM15 8.57922L7.92147 10.1471L6.35356 3.06868L13.432 1.50068L15.0005 8.57897L15 8.57922Z"
          fill={color}
        />
        <path
          d="M3 8.25045H4.25V9.50045H3V8.25045ZM3 12.2505H4.25V13.5005H3V12.2505ZM7 12.2505H8.25V13.5005H7V12.2505ZM5 10.2505H6.25V11.5005H5V10.2505ZM7.5455 3.83067L8.76594 3.56055L9.03606 4.78102L7.81563 5.05114L7.5455 3.83067ZM12.3165 6.86889L13.5369 6.59858L13.8073 7.81902L12.5868 8.08933L12.3165 6.86889ZM9.93137 5.34861L11.1518 5.0783L11.4221 6.2987L10.2017 6.56902L9.93137 5.34861Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6329_9893">
          <rect width="16" height="16" fill={color} transform="translate(0.5 0.000488281)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Dice;
