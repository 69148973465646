import { Error } from 'assets/svgs';
import { useTranslation } from 'next-i18next';

import { CardGridEmpty } from './styles';

const NoGamesCard = () => {
  const { t } = useTranslation();

  return (
    <CardGridEmpty>
      <Error scale={1.25} />
      <p>{t('noGamesToDisplay')}</p>
    </CardGridEmpty>
  );
};

export default NoGamesCard;
