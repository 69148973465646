import React from 'react';
import WrappedImage from 'components/WrappedImage';
import { useImageSizes } from 'hooks/interface/useImageSizes';
import { StaticImageData } from 'next/image';
import Link from 'next/link';

import { HeroBannerCardElement } from './styles';

interface HeroBannerCard {
  image: StaticImageData | string;
  alt: string;
  href: string;

  /** As width / height */
  aspectRatio: number;
}

/**
 * Used at the top of the homepage.
 */
const HeroBannerCard = ({ image, alt, aspectRatio, href }: HeroBannerCard) => {
  const imageSizes = useImageSizes('90vw', '45vw', '30vw');

  return (
    <Link href={href} passHref>
      <HeroBannerCardElement $aspectRatio={aspectRatio}>
        <WrappedImage
          src={image}
          alt={alt}
          layout="fill"
          objectFit="contain"
          objectPosition="bottom"
          sizes={imageSizes}
          quality={100}
          priority
        />
      </HeroBannerCardElement>
    </Link>
  );
};

export default React.memo(HeroBannerCard);
