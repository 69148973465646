import React from 'react';
import { SvgProps } from 'assets/svgs';
import {
  Baccarat,
  Blackjack,
  BonusBuy,
  Dice,
  GameShow,
  LiveCasino,
  Original,
  Roulette,
  Slots,
  TableGames,
} from 'assets/svgs/casino';
import { GameCategoryType } from 'generated/graphql';

const mapping: Record<GameCategoryType, React.ReactElement> = {
  [GameCategoryType.ORIGINALS]: <Original />,
  [GameCategoryType.LIVE_CASINO]: <LiveCasino />,
  [GameCategoryType.ROULETTE]: <Roulette />,
  [GameCategoryType.SLOTS]: <Slots />,
  [GameCategoryType.GAME_SHOWS]: <GameShow />,
  [GameCategoryType.TABLE_GAMES]: <TableGames />,
  [GameCategoryType.BLACKJACK]: <Blackjack />,
  [GameCategoryType.BONUS_BUY]: <BonusBuy />,
  [GameCategoryType.BACCARAT]: <Baccarat />,
  [GameCategoryType.SHUFFLE_PICKS]: <Dice />, // Need to update this
};

const GameCategoryIcon = ({
  category,
  ...rest
}: Partial<SvgProps> & {
  category?: GameCategoryType;
}) => {
  const mapped = category && mapping?.[category] ? mapping[category] : null;
  return mapped ? React.cloneElement(mapped, rest) : <Dice {...rest} />;
};

export default React.memo(GameCategoryIcon);
