import React, { InputHTMLAttributes, ReactNode } from 'react';
import { MAX_ADDRESS_LEN } from 'constants/general';

import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';

import {
  ErrorMessage,
  FormControlWrapper,
  Icon,
  Input,
  InputPrefix,
  InputSuffix,
  InputWrapper,
  Label,
  LabelBlock,
} from './styles';

export type InputVariant = 'solid' | 'outline';

export interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  label?: string;
  error?: string;
  color?: string;
  variant?: InputVariant;

  /**
   * Check valid value
   */
  isValid?: boolean;
  withCopyToClipboard?: boolean;
  resetCopyToClipboard?: boolean;
  isCryptoAddress?: boolean;

  prefix?: ReactNode;
  suffix?: ReactNode;
}

/**
 * Primary UI component for user interaction
 */
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label = '',
      error,
      color,
      variant = 'solid',
      isValid = false,
      withCopyToClipboard,
      value,
      isCryptoAddress,
      prefix,
      suffix,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControlWrapper>
        {label && (
          <LabelBlock>
            <Label htmlFor={rest.id}>{label}</Label>
          </LabelBlock>
        )}
        <InputWrapper>
          {prefix && <InputPrefix>{prefix}</InputPrefix>}
          <Input
            {...rest}
            id={rest.id}
            ref={ref}
            value={value}
            variant={variant}
            isValid={isValid}
            maxLength={isCryptoAddress ? MAX_ADDRESS_LEN : rest.maxLength}
            hasPrefix={!!prefix}
            $hasRightIcon={withCopyToClipboard || Boolean(suffix)}
            hasError={Boolean(error?.length)}
            color={color}
          />

          {withCopyToClipboard && (
            <Icon>
              <ClipboardCopy value={value?.toString() ?? ''} />
            </Icon>
          )}

          {suffix && <InputSuffix>{suffix}</InputSuffix>}
        </InputWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormControlWrapper>
    );
  },
);

export default React.memo(TextInput);
