import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import colors from 'assets/styles/colors';
import { Cross, Home as HomeIcon, Search as SearchIcon } from 'assets/svgs';
import { Flex } from 'components/commonStyles';
import Container from 'components/Container/Container';
import { SearchClearButton } from 'components/FormControls/styles';
import TextInput from 'components/FormControls/TextInput';
import GameCategoryIcon from 'components/GameCategoryIcon';
import ScrollableTabView from 'components/TabView/ScrollableTabView';
import TabView from 'components/TabView/TabView';
import UI from 'constants/ui';
import { GameCategoryType } from 'generated/graphql';
import useBreakpoint, { Breakpoint } from 'hooks/interface/useBreakpoint';
import { useTranslation } from 'next-i18next';

import { RenderClient } from '../../components/RenderClient';
import { TabItem } from '../../components/TabView/TabView.type';

import HomeBannerSection, { BannerItem } from './HomeBannerSection';
import HomeTabAbstract from './HomeTabAbstract';
import HomeTabLobby from './HomeTabLobby';
import {
  HomeTabContainer,
  HomeTabGroup,
  HomeTabSectionSearch,
  HomeTabsSectionWrapper,
} from './styles';

enum HomeTab {
  LOBBY = 'LOBBY',
  ORIGINALS = 'ORIGINALS',
  SLOTS = 'SLOTS',
  LIVE_CASINO = 'LIVE_CASINO',
  TABLE_GAMES = 'TABLE_GAMES',
}

const TAB_DATA: TabItem[] = [
  {
    id: HomeTab.LOBBY,
    name: 'tabLobby',
    Icon: <HomeIcon />,
  },
  {
    id: HomeTab.ORIGINALS,
    name: 'gameOriginals',
    Icon: <GameCategoryIcon category={GameCategoryType.ORIGINALS} scale={1.3} />,
  },
  {
    id: HomeTab.SLOTS,
    name: 'gameSlots',
    Icon: <GameCategoryIcon category={GameCategoryType.SLOTS} scale={1.3} />,
  },
  {
    id: HomeTab.LIVE_CASINO,
    name: 'gameLiveCasino',
    Icon: <GameCategoryIcon category={GameCategoryType.LIVE_CASINO} scale={1.3} />,
  },
  {
    id: HomeTab.TABLE_GAMES,
    name: 'gameTableGames',
    Icon: <GameCategoryIcon category={GameCategoryType.TABLE_GAMES} scale={1.3} />,
  },
];

const HOME_TAB_CATEGORIES: Record<HomeTab, GameCategoryType | null> = {
  [HomeTab.LOBBY]: null,
  [HomeTab.LIVE_CASINO]: GameCategoryType.LIVE_CASINO,
  [HomeTab.ORIGINALS]: GameCategoryType.ORIGINALS,
  [HomeTab.SLOTS]: GameCategoryType.SLOTS,
  [HomeTab.TABLE_GAMES]: GameCategoryType.TABLE_GAMES,
};

const DEBOUNCE_TIME_MS = 250;

interface HomeProps {
  banners: BannerItem[];
}

// Used for the home page and the reset-password page
const Home = ({ banners }: HomeProps) => {
  const { t } = useTranslation();
  const { lessThanBreakpoint } = useBreakpoint();

  const [activeTab, setActiveTab] = useState<string>(HomeTab.LOBBY);
  const [search, setSearch] = useState<string>('');

  const tabName = TAB_DATA.find(tab => tab.id === activeTab)?.name;

  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    DEBOUNCE_TIME_MS,
    [search, setDebouncedSearch],
  );

  return (
    <>
      <RenderClient>
        <RenderClient>
          <HomeBannerSection type="casino" banners={banners} />
        </RenderClient>
      </RenderClient>

      <HomeTabsSectionWrapper>
        <RenderClient>
          {lessThanBreakpoint(Breakpoint.SM) ? (
            <HomeTabGroup>
              <ScrollableTabView
                tabs={TAB_DATA}
                activeTab={activeTab}
                onClick={(_, id) => setActiveTab(id as HomeTab)}
                setActiveTab={setActiveTab}
              />
            </HomeTabGroup>
          ) : (
            <Container padding={{ desktop: `0 ${UI.page.padding.desktop}` }}>
              <Flex wide gap={UI.spacing.md}>
                <TabView
                  tabs={TAB_DATA}
                  activeTab={activeTab}
                  onClick={(_, id) => setActiveTab(id as HomeTab)}
                  setActiveTab={setActiveTab}
                />

                <HomeTabSectionSearch>
                  <TextInput
                    placeholder={t('phSearch')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    prefix={<SearchIcon color={colors.white} />}
                    suffix={
                      search.length > 0 ? (
                        <SearchClearButton onClick={() => setSearch('')}>
                          <Cross width={6} height={6} />
                        </SearchClearButton>
                      ) : undefined
                    }
                  />
                </HomeTabSectionSearch>
              </Flex>
            </Container>
          )}
        </RenderClient>

        <Container padding={{ desktop: `${UI.spacing.lg} ${UI.page.padding.desktop}` }}>
          <HomeTabContainer>
            {activeTab === HomeTab.LOBBY && !debouncedSearch ? (
              <RenderClient>
                <HomeTabLobby />
              </RenderClient>
            ) : (
              <HomeTabAbstract
                heading={tabName ?? ''}
                category={HOME_TAB_CATEGORIES[activeTab as keyof typeof HomeTab]}
                search={debouncedSearch}
              />
            )}
          </HomeTabContainer>
        </Container>
      </HomeTabsSectionWrapper>
    </>
  );
};

export default Home;
