import { memo, useCallback, useEffect, useState } from 'react';
import { Copy } from 'assets/svgs';
import { useTranslation } from 'next-i18next';

import { Button, ClipIcon, CopiedText } from './styles';

interface CopyIconProps {
  value: string;
  color?: string;
  isButton?: boolean;
}

const ClipboardCopy = ({ value, color, isButton }: CopyIconProps) => {
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(value);
    setCopied(true);
  }, [value]);

  return (
    <ClipIcon onClick={handleCopy}>
      {!isButton && (
        <>{copied ? <CopiedText>{t('clipboardCopied')}</CopiedText> : <Copy color={color} />}</>
      )}
      {isButton && <Button value={t(copied ? 'clipboardCopied' : 'btnCopy')} />}
    </ClipIcon>
  );
};

export default memo(ClipboardCopy);
