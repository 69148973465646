import BaseButton from 'components/Buttons/BaseButton';
import UI from 'constants/ui';
import styled from 'styled-components';

export const ClipIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  height: ${UI.spacing['3xl']};
`;

// TODO: Darcy confirming the interaction for copied state
export const CopiedText = styled.p`
  font-size: ${UI.text.sm};
`;

export const Button = styled(BaseButton)`
  width: 65px;
`;
