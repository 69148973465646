import React, { useMemo } from 'react';
import { useMeasure } from 'react-use';
import { CircularLoadingIndicator } from 'assets/svgs';
import { Flex, Padded } from 'components/commonStyles';
import { H3 } from 'components/Text/styles';
import UI from 'constants/ui';
import useBreakpoint, { Breakpoint } from 'hooks/interface/useBreakpoint';
import { useTranslation } from 'next-i18next';
import ShowMoreButtonWithBackground from 'views/games/components/ShowMoreButtonWithBackground';
import ViewMoreBG from 'views/games/components/ViewMoreBg';

import NoGamesCard from './NoGamesCard';
import { CardGridDisplayingText, CardGridElement, CardGridWrapper } from './styles';

interface CardGridProps {
  /**
   * How many cards do we want to fit across?
   * Pass a record to map the number of items to fit
   * against the corresponding breakpoints
   */
  columns: Record<Breakpoint, number>;

  heading?: string | React.ReactNode;
  gap?: string;

  /** Total number of entries */
  entries: number;

  /** Is the actual data empty; excluding polyfills? */
  empty?: boolean;
  emptyState?: React.ReactNode;

  /** Are there more from the backend that we can fetch? */
  canLoadMore?: boolean;
  onClickLoadMore?: () => void;

  canViewMore?: boolean;
  viewMoreOptions?: {
    category?: string;
    href?: string;
  };

  /** The initial number of rows, before loading more. */
  initialRows?: number;
  loading?: boolean;
  children: React.ReactNode;
  customDisplayingText?: string;
}

const CardGrid = (props: CardGridProps) => {
  const {
    heading,
    gap = UI.spacing.md,
    entries,
    children,
    emptyState,
    canLoadMore,
    onClickLoadMore,
    canViewMore,
    viewMoreOptions,
    loading = false,
    customDisplayingText,
  } = props;

  const { breakpoint } = useBreakpoint();
  const columns = props.columns[breakpoint ?? Breakpoint.MD];

  // If not provided, calculate based on count & columns
  const initialRows = props.initialRows
    ? Math.ceil(props.initialRows)
    : Math.ceil(React.Children.count(children) / columns);

  const { t } = useTranslation();

  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const itemHeight = useMemo(() => height / initialRows, [height, initialRows]);

  const empty = props.empty || (React.Children.count(children) === 0 && !loading);
  const displayFetchMoreButton = canLoadMore && onClickLoadMore;
  const displayViewMoreButton = canViewMore && React.Children.count(children) < entries;

  const rowsAreFilled = initialRows * columns >= React.Children.count(children);
  const allAreDisplayed = !loading && !empty && React.Children.count(children) === entries;

  const cards = React.Children.toArray(children).slice(
    0,
    rowsAreFilled ? undefined : columns * initialRows,
  );

  const pcDisplayed = entries === 0 ? 0 : 100 * (cards.length / entries);

  return (
    <CardGridWrapper>
      {!!heading && <H3>{heading}</H3>}

      <CardGridElement ref={ref} $columns={columns} $gap={gap}>
        {cards}
      </CardGridElement>

      {displayFetchMoreButton && (
        <ShowMoreButtonWithBackground
          height={itemHeight}
          margin={false}
          onClick={onClickLoadMore}
          loading={loading}
          percentage={pcDisplayed}
        />
      )}

      {displayViewMoreButton && (
        <ViewMoreBG
          height={itemHeight}
          margin={false}
          href={viewMoreOptions?.href ?? '#'}
          buttonText={t('btwViewMoreGameCategory', {
            category: viewMoreOptions?.category,
            total: entries,
          })}
        />
      )}

      {allAreDisplayed && (
        <Padded y={UI.spacing.md}>
          <Flex justify="center" align="center" gap="0.5rem">
            {onClickLoadMore && <CircularLoadingIndicator percentage={100} />}
            <CardGridDisplayingText>
              {customDisplayingText
                ? customDisplayingText
                : t('txtGamesDisplayed', { count: entries })}
            </CardGridDisplayingText>
          </Flex>
        </Padded>
      )}

      {empty && !loading && emptyState}
      {empty && !loading && !emptyState && <NoGamesCard />}
    </CardGridWrapper>
  );
};

export default CardGrid;
