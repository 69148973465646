import React, { useMemo } from 'react';
import { clientWithoutAuth } from 'apollo/clients';
import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import CardGrid from 'components/Cards/CardGrid';
import TallGameCard from 'components/Cards/TallGameCard';
import { Flex } from 'components/commonStyles';
import GameCategoryIcon from 'components/GameCategoryIcon';
import UI from 'constants/ui';
import { Game, GameCategoryType, useGetGamesQuery } from 'generated/graphql';
import useBreakpoint, { Breakpoint } from 'hooks/interface/useBreakpoint';
import { SKELETON_COLUMNS, useSkeleton } from 'hooks/useSkeletons';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { categoryLink } from 'utils/linkGenerator';

import { useFavouriteGameIds } from '../../hooks/games/useFavouriteGameIds';

export interface HomeTabAbstractProps {
  heading: string;
  category: GameCategoryType | null;
}

interface Props extends HomeTabAbstractProps {
  search: string;
}

const INITIAL_ROWS = 4;
const FETCH_FIRST = 28;

const HomeTabAbstract = ({ heading, category, search }: Props) => {
  const favouriteGameIds = useFavouriteGameIds();

  const { t } = useTranslation();

  const { data, loading } = useGetGamesQuery({
    variables: {
      first: FETCH_FIRST,
      skip: 0,
      categories: category ? [category as GameCategoryType] : undefined,
      isOriginal: category === GameCategoryType.ORIGINALS,
      search: search,
    },
    fetchPolicy: 'cache-and-network',
    client: clientWithoutAuth,
  });

  const { lessThanBreakpoint } = useBreakpoint();
  const gap = lessThanBreakpoint(Breakpoint.LG)
    ? `${pxToRem(12)} ${UI.spacing.xs}`
    : `${UI.spacing.lg} ${UI.spacing.md}`;

  /** Grab games from data, else fill with dummy polyfill data for loading-state of cards. */
  const games = (data?.games.nodes ?? []).filter(g => !!g.image?.key);
  const totalGames = data?.games.totalCount ?? 0;

  const polyfillGames = useSkeleton();

  const cards = useMemo(() => (loading ? polyfillGames : games), [loading, games, polyfillGames]);

  return (
    <CardGrid
      heading={
        category && (
          <Link href={categoryLink(category)}>
            <a>
              <Flex align="center" justify="center" gap={UI.spacing.xs}>
                <GameCategoryIcon category={category} color={colors.primaryViolet} scale={1.3} />
                {t(heading)}
              </Flex>
            </a>
          </Link>
        )
      }
      initialRows={INITIAL_ROWS}
      gap={gap}
      columns={SKELETON_COLUMNS}
      loading={loading}
      empty={!games.length}
      entries={totalGames}
      canViewMore
      viewMoreOptions={
        category
          ? {
              category: t(category),
              href: categoryLink(category),
            }
          : undefined
      }
    >
      {cards.map(game => (
        <TallGameCard
          key={game.id}
          game={game as Game}
          isFavourite={favouriteGameIds.includes(game.id)}
        />
      ))}
    </CardGrid>
  );
};

export default HomeTabAbstract;
