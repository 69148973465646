import React from 'react';

interface Props {
  scale?: number;
}

const Error = ({ scale = 1 }: Props) => {
  const width = `${scale * 48}`;
  const height = `${scale * 48}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1118.6 1118.6"
    >
      <path
        d="m800.8 559.3 311.24-311.24c8.74-8.74 8.74-22.91 0-31.64L902.18 6.55c-8.74-8.74-22.9-8.74-31.64 0L559.3 317.79 248.06 6.55c-8.74-8.74-22.91-8.74-31.64 0L6.55 216.41c-8.74 8.74-8.74 22.91 0 31.64l311.24 311.24L6.55 870.54c-8.74 8.74-8.74 22.91 0 31.64l209.86 209.86c8.74 8.74 22.91 8.74 31.64 0L559.29 800.8l311.24 311.24c8.74 8.74 22.9 8.74 31.64 0l209.86-209.86c8.74-8.74 8.74-22.91 0-31.64L800.79 559.3Z"
        style={{
          fill: '#ea1f4b',
        }}
      />
    </svg>
  );
};

export default Error;
