import React from 'react';

import { SvgProps } from '..';

const TableGames = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00003 12.4999C10.4853 12.4999 12.4999 10.4852 12.4999 8.00001C12.4999 5.51478 10.4853 3.5001 8.00003 3.5001C5.5148 3.5001 3.50012 5.51478 3.50012 8.00001C3.50012 10.4852 5.5148 12.4999 8.00003 12.4999Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.84385 9.38072C6.9785 9.55782 7.15391 9.6998 7.35519 9.79459C7.55646 9.88938 7.77765 9.93417 7.99994 9.92516C8.70865 9.92516 9.28359 9.49396 9.28359 8.96258C9.28359 8.4312 8.70865 8 7.99994 8C7.29123 8 6.71692 7.5688 6.71692 7.0368C6.71692 6.5048 7.29123 6.07485 7.99994 6.07485C8.22224 6.06584 8.44342 6.11063 8.64469 6.20542C8.84597 6.30021 9.02138 6.44219 9.15603 6.61929"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 9.92514V10.5667" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 5.43333V6.07485" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.6709 1.12569L7.14565 3.58223"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.80457 3.57163L9.25132 1.11137"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.38257 5.71395L3.74701 6.53031"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.56715 5.08925L2.66003 3.4721"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.71106 12.5877L4.60013 10.9493"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.76268 9.51761L1.40881 10.3601"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.329 14.8743L8.85425 12.4178"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.19553 12.4283L6.74878 14.8886"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6173 10.2861L12.2528 9.4697"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4327 10.9107L13.3399 12.5279"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2888 3.41235L11.3998 5.05066"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2374 6.48241L14.5913 5.63992"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TableGames;
