import { useMemo } from 'react';

import useBreakpoint, { Breakpoint } from './interface/useBreakpoint';

const INITIAL_ROWS = 3;

export const SKELETON_COLUMNS: Record<Breakpoint, number> = {
  [Breakpoint.XS]: 3,
  [Breakpoint.SM]: 4,
  [Breakpoint.MD]: 5,
  [Breakpoint.LG]: 7,
  [Breakpoint.XL]: 7,
};

export const useSkeleton = () => {
  const { breakpoint } = useBreakpoint();

  return useMemo(
    () =>
      [...Array(INITIAL_ROWS * SKELETON_COLUMNS[breakpoint ?? Breakpoint.MD]).keys()].map(key => ({
        id: key.toString(),
      })),
    [breakpoint],
  );
};
