import { clientWithoutAuth } from 'apollo/clients';
import colors from 'assets/styles/colors';
import { Provider } from 'assets/svgs';
import ProviderCard from 'components/Cards/ProviderCard';
import { useGetGameProvidersQuery } from 'generated/graphql';
import { useCarousel } from 'hooks/useCarousel';
import { useTranslation } from 'next-i18next';
import { calculateCardCount, CarouselType } from 'utils/carousel';
import { providerLink } from 'utils/linkGenerator';

import FittedCarousel from '../Carousel/FittedCarousel';

const ProviderCarousel = () => {
  const { t } = useTranslation();
  const { carouselSize } = useCarousel();

  const { data } = useGetGameProvidersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    client: clientWithoutAuth,
  });

  return (
    <FittedCarousel
      size={carouselSize}
      hideViewAll
      fitCardsFunc={parentWidth => calculateCardCount(parentWidth, CarouselType.PROVIDERS)}
      title={
        <>
          <Provider color={colors.primaryViolet} scale={1.3} />
          <span>{t('sectionProviders')}</span>
        </>
      }
      href="/casino/providers"
    >
      {data?.gameProviders.map(item => (
        <ProviderCard
          key={item.name}
          image={item.image?.key || ''}
          href={providerLink(item.slug)}
        />
      ))}
    </FittedCarousel>
  );
};

export default ProviderCarousel;
