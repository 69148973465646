import { Maybe } from 'graphql/jsutils/Maybe';

export function gameLink(slug: string): string {
  return `/games/${slug}`;
}

export function categoryLink(name: string): string {
  return `/casino/categories/${encodeURIComponent(name.toLowerCase())}`;
}

export function providerLink(slug: string): string {
  return `/casino/providers/${slug}`;
}

const IMGIX = process.env.NEXT_PUBLIC_IMGIX_URL;

/**
 * Gets the image source for a given key from Game or Provider (or otherwise) type.
 */
export const getImageFromKey = (key?: string | Maybe<string>, placeholder = false) => {
  if (placeholder && !key) return '/images/placeholder.jpeg';

  return key ? `${IMGIX}${key}` : '/images/default-game-tile.png';
};

export const getReferralLink = (code: string) => {
  return `${window.location.origin}?r=${code}`;
};
