import React from 'react';
import { Chevron } from 'assets/svgs';
import HeroBannerCard from 'components/Cards/HeroBannerCard';
import FittedCarousel from 'components/Carousel/FittedCarousel';
import Container from 'components/Container/Container';
import UI from 'constants/ui';
import useBreakpoint, { Breakpoint } from 'hooks/interface/useBreakpoint';
import { StaticImageData } from 'next/image';
import { calculateCardCount, CarouselType } from 'utils/carousel';

import {
  ArrowIcon,
  HomeBannerArrowLeft,
  HomeBannerArrowRight,
  HomeBannerCarousel,
  HomeBannerSectionWrapper,
} from './styles';

const DEFAULT_CARDS_TO_DISPLAY = 3;

export type BannerSectionVariant = 'casino' | 'sports';

interface HomeBannerSectionProps {
  type: BannerSectionVariant;
  banners?: BannerItem[];
}

export type BannerItem = {
  type: BannerSectionVariant;
  image: string | StaticImageData;
  href: string;
  alt: string;
};

const aspectRatios = 1.778926442;

const desktopPadding = `${UI.spacing.xl} ${UI.page.padding.desktop} ${UI.page.padding.mobile}`;

const HomeBannerSection = ({ type = 'casino', banners }: HomeBannerSectionProps) => {
  const { lessThanBreakpoint } = useBreakpoint();

  const cards: BannerItem[] = (banners?.filter(banner => banner.type === type) ?? []).map(card => {
    return {
      ...card,
      href: `${type === 'sports' ? '/sports' : ''}${card.href}`,
    };
  });

  // Here we want to conditionally render with JS rather than using
  // media queries because setting `display: none` on our FittedCarousel
  // still wastes resources mounting it for larger screens.
  return lessThanBreakpoint(Breakpoint.XS) ? (
    <HomeBannerCarousel>
      <FittedCarousel
        hideHeader
        hideViewAll
        slidesPerView={1.05}
        fade={false}
        fitCardsFunc={parentWidth => calculateCardCount(parentWidth, CarouselType.PROMOTIONS)}
      >
        {cards.map((card, key) => (
          <HeroBannerCard key={key} {...card} aspectRatio={aspectRatios} />
        ))}
      </FittedCarousel>
    </HomeBannerCarousel>
  ) : (
    <Container padding={{ desktop: desktopPadding }}>
      <HomeBannerSectionWrapper>
        <FittedCarousel
          hideHeader
          hideViewAll
          fade={false}
          slidesPerGroup={1}
          allowTouchMove={false}
          loop={cards?.length < DEFAULT_CARDS_TO_DISPLAY}
          fitCardsFunc={parentWidth => calculateCardCount(parentWidth, CarouselType.PROMOTIONS)}
          navigatePrevComponent={(onClick, hasPrevPage) =>
            hasPrevPage && (
              <HomeBannerArrowLeft>
                <ArrowIcon onClick={onClick}>
                  <Chevron direction="left" scale={1.3} strokeWidth={2} />
                </ArrowIcon>
              </HomeBannerArrowLeft>
            )
          }
          navigateNextComponent={(onClick, hasNextPage) =>
            hasNextPage && (
              <HomeBannerArrowRight>
                <ArrowIcon onClick={onClick}>
                  <Chevron direction="right" scale={1.3} strokeWidth={2} />
                </ArrowIcon>
              </HomeBannerArrowRight>
            )
          }
        >
          {cards?.map((card, key) => {
            return <HeroBannerCard key={key} {...card} aspectRatio={aspectRatios} />;
          })}
        </FittedCarousel>
      </HomeBannerSectionWrapper>
    </Container>
  );
};

export default React.memo(HomeBannerSection);
