import React, { useMemo } from 'react';
import Link from 'next/link';
import { getImageFromKey } from 'utils/linkGenerator';

import {
  ProviderCardBlock,
  ProviderCardElement,
  ProviderCardWrapper,
  ProviderImage,
} from './styles';

interface ProviderCardProps {
  image: string;
  href?: string;
}

const ProviderCardItem = ({ image }: ProviderCardProps) => {
  const imageSrc = useMemo(() => getImageFromKey(image, true), [image]);

  return (
    <ProviderCardBlock>
      <ProviderCardElement>
        <ProviderImage src={imageSrc} alt="game-provider-card" placeholder="blur" />
      </ProviderCardElement>
    </ProviderCardBlock>
  );
};

const ProviderCard = ({ image, href = '' }: ProviderCardProps) => {
  return (
    <ProviderCardWrapper $canClick={href.length > 0}>
      {href ? (
        <Link href={href} passHref>
          <a>
            <ProviderCardItem image={image} />
          </a>
        </Link>
      ) : (
        <ProviderCardItem image={image} />
      )}
    </ProviderCardWrapper>
  );
};

export default ProviderCard;
