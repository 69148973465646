import React, { useCallback, useMemo } from 'react';
import WrappedImage from 'components/WrappedImage';
import { GetGameBySlugQuery } from 'generated/graphql';
import Link from 'next/link';
import { imageQuality } from 'utils/imageQuality';
import { gameLink, getImageFromKey } from 'utils/linkGenerator';

import { GameCardBorder } from '../styles';

import { TallGameCardElement, TallGameCardWrapper } from './styles';

interface TallGameCardProps {
  // No game when we're loading
  game?: GetGameBySlugQuery['game'];
  hidePreview?: boolean;
  isFavourite?: boolean;
  onSelected?: (payload: React.MouseEvent) => void;
}

const quality = imageQuality();

/**
 * The default game card for displaying original and provider games.
 * @note 30 JAN 2023 - Removed GameCardPreview for launch.
 */
const TallGameCard = ({ game, onSelected = () => {} }: TallGameCardProps) => {
  const image = useMemo(() => getImageFromKey(game?.image?.key, !game?.name), [game]);

  const loader = useCallback(({ src, width }: { src: string; width: number }) => {
    return `${src}&width=${width}&q=${quality}`;
  }, []);

  return (
    <TallGameCardWrapper onClick={onSelected}>
      <TallGameCardElement>
        <Link href={gameLink(game?.slug ?? '')} prefetch={false}>
          <a>
            <WrappedImage
              src={`${image}?auto=format`}
              loader={loader}
              alt="tall-game-card"
              layout="fill"
              objectFit="contain"
              placeholder="blur"
              sizes="180px"
              blurDataURL={`${image}?auto=compress&blur=200&width=20`}
            />
            <GameCardBorder $accent={game?.accentColor} />
          </a>
        </Link>
      </TallGameCardElement>
    </TallGameCardWrapper>
  );
};

export default TallGameCard;
