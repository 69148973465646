import React from 'react';

interface CopyProps {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Copy = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: CopyProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.719 4H5.78A1.78 1.78 0 0 0 4 5.781v6.938A1.78 1.78 0 0 0 5.781 14.5h6.938a1.78 1.78 0 0 0 1.781-1.781V5.78A1.78 1.78 0 0 0 12.719 4Z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path
        d="M11.984 4 12 3.25a1.755 1.755 0 0 0-1.75-1.75H3.5a2.006 2.006 0 0 0-2 2v6.75A1.755 1.755 0 0 0 3.25 12H4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Copy;
