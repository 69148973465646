import React from 'react';
import Link from 'next/link';

import { ButtonColor } from './BaseButton';
import { PrimaryButton, SecondaryButton, SuccessButton, TertiaryButton } from './styles';

import styles from './LinkButton.module.scss';

interface LinkButtonProps {
  color?: ButtonColor;
  href: string;
  value: string;
  width?: string;
  openInNewTab?: boolean;
  fontWeight?: string;
  onClick?: () => void;
}

const linkButtons: Record<ButtonColor, typeof PrimaryButton> = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
  success: SuccessButton,
};

const LinkButton = ({
  href,
  value,
  width = 'fit-content',
  color = 'primary',
  openInNewTab = false,
  fontWeight = '500',
  onClick,
}: LinkButtonProps) => {
  const ButtonComponent = linkButtons[color];

  return (
    <>
      {openInNewTab ? (
        <a href={href} target="_blank" rel="noopener noreferrer" className={styles.link}>
          <ButtonComponent onClick={onClick} as="a" fontWeight={fontWeight}>
            {value}
          </ButtonComponent>
        </a>
      ) : (
        <Link
          href={href}
          passHref
          style={{
            width,
          }}
        >
          <ButtonComponent onClick={onClick} as="a" fontWeight={fontWeight} className={styles.link}>
            {value}
          </ButtonComponent>
        </Link>
      )}
    </>
  );
};

export default LinkButton;
