import { pxToRem } from 'assets/styles/convertors';
import { magicMediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const HomeTabsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HomeTabContainer = styled.div`
  width: 100%;
`;

export const HomeTabSectionSearch = styled.div`
  flex-grow: 1;
`;

export const HomeBannerCarousel = styled.div`
  padding: 0.25rem 0 0 1rem;
  margin-bottom: ${pxToRem(10)};

  ${magicMediaQueries(Breakpoint.MD)`
    padding: 1rem 0 1rem 1rem;
    margin-bottom: 1rem;
  `};
`;

export const HomeTabGroup = styled.div`
  padding: ${UI.spacing.sm} ${UI.spacing.sm} ${pxToRem(4)} ${UI.spacing.sm};

  ${magicMediaQueries(Breakpoint.MD)`
    padding: ${UI.spacing.sm};
  `};
`;

export const HomeTabLobbyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${UI.spacing.xl};

  ${magicMediaQueries(Breakpoint.MD)`
    gap: ${UI.spacing['2xl']};
  `};
`;

export const ArrowIcon = styled.span`
  width: 3rem;
  height: 3rem;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  z-index: ${UI.z.base};
`;

export const HomeBannerArrow = styled.div`
  position: absolute;
  height: 100%;
  width: 4rem;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (hover: hover) {
    &:hover {
      ${ArrowIcon} {
        display: flex;
        opacity: 1;
      }
    }
  }
`;

export const HomeBannerArrowLeft = styled(HomeBannerArrow)`
  left: -3rem;
`;

export const HomeBannerArrowRight = styled(HomeBannerArrow)`
  right: -3rem;
`;

export const HomeBannerSectionWrapper = styled.div`
  position: relative;
  .swiper {
    padding-top: 0;
  }

  @media (hover: hover) {
    &:hover {
      ${ArrowIcon} {
        display: flex;
        opacity: 1;
      }
    }
  }
`;
