import { useCallback } from 'react';
import LinkButton from 'components/Buttons/LinkButton';
import { Flex } from 'components/commonStyles';
import { useRouter } from 'next/router';

import { ShowMoreBackground } from '../styles';

const DEFAULT_CARD_HEIGHT_PX = 150;

interface ViewMoreBGProps {
  href: string;
  buttonText: string;
  margin?: boolean;
  height?: number;
}

const ViewMoreBG = ({
  href,
  buttonText,
  margin = true,
  height = DEFAULT_CARD_HEIGHT_PX,
}: ViewMoreBGProps) => {
  const router = useRouter();

  const onClickBackground = useCallback(() => {
    router.push(href);
  }, [href, router]);

  return (
    <ShowMoreBackground $height={height} $margin={margin} onClick={onClickBackground}>
      <Flex align="center" justify="center">
        <LinkButton href={href} value={buttonText} />
      </Flex>
    </ShowMoreBackground>
  );
};

export default ViewMoreBG;
