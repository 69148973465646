import React, { useRef } from 'react';
import { CONTAINER_CLASSNAME } from 'components/Container/Container';
import useIsDescendant from 'hooks/interface/useIsDescendant';

import { ScrollableTabViewContainer, ScrollableTabViewElement } from './styles';
import TabView from './TabView';
import { TabViewProps } from './TabView.type';

/**
 * Used on tiny screens where the regular TabView might get cut off.
 */
const ScrollableTabView = (props: TabViewProps) => {
  const ref = useRef<HTMLDivElement>(null);

  // Determine if we are being rendered in a page container;
  // if so, automagically adjust offset to match page padding.
  const isInsideContainer = useIsDescendant(`.${CONTAINER_CLASSNAME}`, ref);

  return (
    <ScrollableTabViewContainer ref={ref} $isInsideContainer={isInsideContainer}>
      <ScrollableTabViewElement>
        <TabView {...props} />
      </ScrollableTabViewElement>
    </ScrollableTabViewContainer>
  );
};

export default ScrollableTabView;
